import { useCallback, useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { showFeedback, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import { useLocation } from '@reach/router'
import { goTo, getSourceUrl } from 'utils'

export const useHandleQueryStrings = () => {
  const location = useLocation()
  const {
    changeLeadData,
    leadData: { partner }
  } = useLead()

  const getQueries = (location) => {
    const _t = new URLSearchParams(location?.search).get('_t')
    const key = new URLSearchParams(location?.search).get('key')
    const token = new URLSearchParams(location?.search).get('token')
    const utmSource = new URLSearchParams(location?.search).get('utm_source')

    return {
      _t,
      key,
      token,
      utmSource
    }
  }

  const handleQueryStrings = useCallback(() => {
    const { _t, key, token, utmSource } = getQueries(location)
    const requestBody = {
      _t,
      key,
      token,
      utm_source: utmSource,
      source_url: getSourceUrl()
    }

    datadogRum.addAction('crossSell', {
      requestBody
    })

    http.proposal
      .newCreateLead(requestBody)
      .then(({ next_step }) => {
        goTo(next_step)
      })
      .catch(() => {
        goTo('basic-data')
        showFeedback({
          detail: {
            message: '',
            show: false,
            type: ''
          }
        })
      })
  }, [location])

  useEffect(() => {
    if (partner) changeLeadData({ partner: null })

    handleQueryStrings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLeadData, handleQueryStrings])
}
